const initialState = {
  isTamil: false,
};

export default function LanguageReducer(state = initialState, action) {
  switch (action.type) {
    case "ACTION_CHANGE_LANGUAGE":
      return {
        isTamil: action.isTamil,
      };
    default:
      return state;
  }
}
