import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "../../src/Router/PrivateRoute";
import { ROUTES } from "../../src/Router/RouteConstant";
import SpinLoading from "../Components/SpinLoading";
import SharethisApp from "../Pages/ShareApp/SharethisApp";

const LoginApp = React.lazy(() =>
  import("../../src/Pages/Authentication/Login")
);
const Verification = React.lazy(() =>
  import("../Pages/Authentication/Verification")
);
const Signup = React.lazy(() =>
  import("../../src/Pages/Authentication/SignUp")
);
const LayoutArea = React.lazy(() => import("../Components/Layout"));
const NotFound = React.lazy(() => import("../../src/Pages/404/404"));
const PrivacyPolicy = React.lazy(() => import("../Components/PrivacyPolicy"));
const TermsAndConditions = React.lazy(() =>
  import("../Components/TermsAndConditions")
);
const ForgetPasswordview = React.lazy(() =>
  import("../../src/Pages/Authentication/ForgetPasswordView")
);
const Disclaimer = React.lazy(() => import("../Components/Disclaimer"));

const Routing = ({ isTamil }) => {
  return (
    <Switch>
      <Route exact path={ROUTES.LOGIN}>
        <Suspense fallback={<SpinLoading loading={true} indexload={true} />}>
          <LoginApp />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.SIGNUP}>
        <Suspense fallback={<SpinLoading loading={true} indexload={true} />}>
          <Signup />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.FORGET_PASSWORD}>
        <Suspense fallback={<SpinLoading loading={true} />}>
          <ForgetPasswordview />
        </Suspense>
      </Route>
      <Route exact path={ROUTES.VERIFICATION_PAGE}>
        <Suspense fallback={<SpinLoading loading={true} />}>
          <Verification />
        </Suspense>
      </Route>
      {/* PrivacyPolicy-Publicroute */}
      <Route exact path="/privacy_policy">
        <Suspense fallback={<SpinLoading loading={true} />}>
          <PrivacyPolicy />
        </Suspense>
      </Route>
      {/* Terms & Conditions-Publicroute / */}
      <Route exact path="/terms_and_conditions">
        <Suspense fallback={<SpinLoading loading={true} />}>
          <TermsAndConditions />
        </Suspense>
      </Route>
      {/* Disclaimer-Publicroute / */}
      <Route exact path="/disclaimer_1">
        <Suspense fallback={<SpinLoading loading={true} />}>
          <Disclaimer />
        </Suspense>
      </Route>
      {/* SharethiApp/ */}
      <Route exact path="/sharethisApp_1">
        <Suspense fallback={<SpinLoading loading={true} />}>
          <SharethisApp isTamil={isTamil} />
        </Suspense>
      </Route>
      <PrivateRoute path={ROUTES.LAYOUT}>
        <Suspense fallback={<SpinLoading loading={true} />}>
          <LayoutArea />
        </Suspense>
      </PrivateRoute>
      <Route path="*">
        <Suspense fallback={<SpinLoading loading={true} />}>
          <NotFound />
        </Suspense>
      </Route>
    </Switch>
  );
};

// Map Redux state to props
const mapStateToProps = (state) => ({
  isTamil: state.LanguageReducer.isTamil, // Adjust according to your state structure
});

export default connect(mapStateToProps)(Routing);
