import axios from "axios";
import { BASEURL } from "./APIConstants";

const client = axios.create({
  baseURL: BASEURL,
  responseType: "json",
  // timeout: 30000,
});

/**
 * Request Wrapper with default success/error actions
 */
const httpRequest = function (options) {
  const onSuccess = function (response) {
    return response.data;
  };

  const onError = function (error) {
    return Promise.reject(
      error.response || error.message || error.config || error.request
    );
  };

  return client(options).then(onSuccess).catch(onError);
};

class APIRequest {
  static async getPostService(url, inputdata, reqHeader) {
    var token = localStorage.getItem("agarathi_token");
    const headercontent = token
      ? { "Content-Type": "application/json", Authorization: "Bearer " + token }
      : { "Content-Type": "application/json" };
    const response = await httpRequest({
      data: inputdata,
      method: "post",
      headers: { ...headercontent, ...reqHeader },
      url: url,
    });
    return response;
  }
  static async getDeleteService(url, inputdata, reqheader) {
    var token = localStorage.getItem("agarathi_token");
    const headercontent = token
      ? { "Content-Type": "application/json", Authorization: "Bearer " + token }
      : { "Content-Type": "application/json" };
    const response = await httpRequest({
      data: inputdata,
      method: "delete",
      headers: { ...headercontent, ...reqheader },
      url: url,
    });
    return response;
  }

  static async getGetService(url) {
    var token = localStorage.getItem("agarathi_token");
    const headercontent = token
      ? { "Content-Type": "application/json", Authorization: "Bearer " + token }
      : { "Content-Type": "application/json" };
    const response = await httpRequest({
      method: "GET",
      headers: headercontent,
      url: url,
    });
    return response;
  }

  static async getPutService(url, inputdata, reqHeader) {
    var token = localStorage.getItem("agarathi_token");
    const headercontent = token
      ? { "Content-Type": "application/json", Authorization: "Bearer " + token }
      : { "Content-Type": "application/json" };
    const response = await httpRequest({
      data: inputdata,
      method: "PUT",
      headers: { ...headercontent, ...reqHeader },
      url: url,
    });
    return response;
  }
}

export { httpRequest, APIRequest };
